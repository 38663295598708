import { useCallback, useRef } from 'react'
import GoogleMap, { ChildComponentProps } from 'google-map-react'
import cx from 'classnames'

import { Position } from '@/utils/location'
import { useGoogleApiKey } from '@/utils/env'
import { Spot } from '@/utils/spot'

import KasaPin, { KasaPinProps } from '@/components/KasaPin'

import styles from './KasaMap.module.css'

// HACK: type safe
function MapKasaPin(props: KasaPinProps & Partial<ChildComponentProps>) {
  return <KasaPin {...props} />
}

function CurrentDot(
  props: { className?: string } & Partial<ChildComponentProps>
) {
  return <div className={cx(styles.currentDot, props.className)} />
}

export type MapPin = {
  id: string
  active: boolean
  selected: boolean
  latitude: Position['latitude']
  longitude: Position['longitude']
}

type KasaMapProps = {
  className?: string
  position: Position
  currentPosition?: Position
  zoom: number
  onChange: (position: Position, zoom: number) => void
  spots: Spot[]
  selectingSpot?: Spot | null
  onSpotClick: (spot: Spot) => void
}

function KasaMap(props: KasaMapProps) {
  const {
    className,
    currentPosition,
    position,
    zoom,
    onChange,
    spots,
    selectingSpot,
    onSpotClick,
  } = props

  const firstChangeRef = useRef(false)
  const onGoogleMapChange = useCallback(
    (value: GoogleMap.ChangeEventValue) => {
      // HACK: GoogleMap will call onChange immediately after set position
      // So we need to ignore the first change event
      if (!firstChangeRef.current) {
        firstChangeRef.current = true
        return
      }
      onChange(
        { latitude: value.center.lat, longitude: value.center.lng },
        value.zoom
      )
    },
    [onChange]
  )

  const key = useGoogleApiKey()

  return (
    <div className={cx(styles.container, className)}>
      <GoogleMap
        bootstrapURLKeys={{ key }}
        options={{
          disableDefaultUI: true,
          clickableIcons: false,
          styles: [
            {
              featureType: 'landscape.man_made',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.park',
              stylers: [{ visibility: 'on' }],
            },
          ],
        }}
        center={{ lat: position.latitude, lng: position.longitude }}
        zoom={zoom}
        onChange={onGoogleMapChange}
      >
        {currentPosition && (
          <CurrentDot
            className={styles.pin}
            lat={currentPosition.latitude}
            lng={currentPosition.longitude}
          />
        )}

        {spots.map((spot) => (
          <MapKasaPin
            className={styles.pin}
            key={spot.scd}
            lat={spot.position.lat}
            lng={spot.position.lng}
            active={!spot.isMaintenance}
            selected={selectingSpot?.scd === spot.scd}
            onClick={() => {
              onSpotClick(spot)
            }}
          />
        ))}
      </GoogleMap>
    </div>
  )
}

export default KasaMap
